import React, { FC, useEffect, useState } from "react";
import {
    Badge,
    Card,
    Collapse,
    FormControl,
    InputGroup
} from "react-bootstrap";
import { MutatorProperty } from "../../../models/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { useMutatorState } from "../../../graphql/hooks";
import styles from "./CustomTypeCard.module.scss";
import cx from "classnames";

interface Props {
    fieldProperty: MutatorProperty;
    typeProperty: MutatorProperty;
    getVariantByType: (type: string) => string;
    onChange: (name: string, value: string) => void;
}

const MutatorStringFieldCardImplementation: FC<Props> = ({
    fieldProperty,
    typeProperty,
    getVariantByType,
    onChange
}) => {
    const mutatorState = useMutatorState();
    const [showMeta, updateShowMeta] = useState(mutatorState.showMeta);

    useEffect(() => {
        if (showMeta !== mutatorState.showMeta) {
            updateShowMeta(mutatorState.showMeta);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mutatorState.showMeta]);

    return (
        <Card className="text-inverse inner-card">
            <Card.Header
                className={`d-flex align-items-center ${styles.cardHeader}`}
            >
                {fieldProperty.name}
                <Badge
                    bg={getVariantByType(fieldProperty.type)}
                    className="mx-2"
                >
                    {fieldProperty.type}
                </Badge>
                {typeProperty.meta ? (
                    <div
                        className="float-end ms-auto mouseHover"
                        onClick={() => updateShowMeta(!showMeta)}
                        title="Help and additional info"
                    >
                        <FontAwesomeIcon
                            icon={faCircleInfo}
                            className={cx({
                                "text-inverse-25": !showMeta,
                                "text-inverse-50": showMeta
                            })}
                        />
                    </div>
                ) : null}
            </Card.Header>
            <Card.Body className={`${styles.cardBody}`}>
                <InputGroup>
                    <InputGroup.Text>Value</InputGroup.Text>
                    <FormControl
                        type="string"
                        value={fieldProperty.value}
                        onChange={e => {
                            onChange(fieldProperty.name, e.target.value);
                        }}
                    />
                </InputGroup>
            </Card.Body>
            {typeProperty.meta && (
                <Collapse in={showMeta}>
                    <div>
                        <Card.Footer className={styles.footerInfo}>
                            <div className="bread-text text-inverse-50">
                                {typeProperty.meta.description}
                            </div>
                        </Card.Footer>
                    </div>
                </Collapse>
            )}
        </Card>
    );
};

export const MutatorStringFieldCard = React.memo(
    MutatorStringFieldCardImplementation
);
