import {
    BatchBuild,
    BatchBuildsData,
    BatchBuildState,
    Build,
    BuildJobData,
    DailyAnalytics,
    EditPanelStateData,
    EditPanelValidation,
    Network,
    NotificationStateData,
    PaginatorStateData,
    PartialRevisionData,
    PartialRevisionVariables,
    Product,
    Project,
    ProjectEnvironment,
    Revision,
    RevisionData,
    RevisionVariables,
    SortingData,
    SortingMode,
    StateData,
    TechStackConfig,
    ToastData,
    Types
} from "../models/types";
import {
    EditPanelState,
    MutatorStateData,
    SortingState,
    SortingStateData,
    TechStackType,
    VariationCollapseState
} from "../models/common";
import { ApolloClient, MutationFunction } from "@apollo/client";
import {
    GET_LOCAL_EDITPANEL_STATE,
    GET_LOCAL_MUTATOR_STATE,
    GET_LOCAL_NOTIFICATION_STATE,
    GET_LOCAL_PAGINATOR_STATE,
    GET_LOCAL_SORTING_STATE,
    GET_LOCAL_STATE,
    GET_REVISION
} from "../graphql/queries";
import { initialSortingState } from "../graphql/client";

export const getDayAndMonthFromUnixTimestamp = (unixTimestamp: number) => {
    const date = new Date(unixTimestamp * 1000);
    return `${date.getUTCDate()}/${date.getUTCMonth() + 1}`;
};

export const getFormattedTime = (datetime: string) => {
    const date = new Date(datetime);
    return getFormattedTimeFromDate(date);
};

export const getFormattedTimeFromUnix = (unixTimestamp: number) => {
    const date = new Date(unixTimestamp * 1000);
    return getFormattedTimeFromDate(date);
};

export const getUnixTimestampFromDateString = (datetime: string): number => {
    const date = new Date(datetime);
    return date.getTime();
};

export const convertSQLDatesToChartDates = (inputDate: string): string => {
    const parts = inputDate.split("-");
    const year = parts[0];
    const month = parts[1];
    const day = parts[2];
    return `${day}/${month}/${year}`;
};

export const getFormattedTimeFromDate = (date: Date) => {
    let currentHours = date.getHours().toString();
    currentHours = ("0" + currentHours).slice(-2);
    let currentMinutes = date.getMinutes().toString();
    currentMinutes = ("0" + currentMinutes).slice(-2);
    const month = date.getMonth() + 1;
    return `${date.getDate()}/${month}/${date.getFullYear()} ${currentHours}:${currentMinutes}`;
};

export const getDatesInRange = (from: number, to: number) => {
    const dates = [];
    const date1 = new Date(from * 1000);
    const date2 = new Date(to * 1000);
    while (date1 <= date2) {
        dates.push(new Date(date1));
        date1.setDate(date1.getDate() + 1);
    }
    return dates;
};

export const getTimestampRangeForDate = (date: Date) => {
    const startOfDay = new Date(date);
    startOfDay.setUTCHours(0, 0, 0, 0);
    const endOfDay = new Date(date);
    endOfDay.setUTCHours(23, 59, 59, 999);
    return {
        startTimestamp: startOfDay.getTime() / 1000,
        endTimestamp: endOfDay.getTime() / 1000
    };
};

export const getLastAnalyticsUpdateDate = () => {
    const lastDataUpdateDate = new Date();
    if (lastDataUpdateDate.getUTCHours() > 6) {
        lastDataUpdateDate.setUTCHours(6, 0, 0);
    } else {
        lastDataUpdateDate.setUTCDate(lastDataUpdateDate.getUTCDate() - 1);
        lastDataUpdateDate.setUTCHours(6, 0, 0);
    }
    return lastDataUpdateDate;
};

export const getLastAnalyticsUpdateDateString = () => {
    const lastDataUpdateDate = getLastAnalyticsUpdateDate();
    const timeZone = lastDataUpdateDate
        .toLocaleTimeString(undefined, {
            timeZoneName: "short",
            hour12: false
        })
        .split(" ")[1];
    return `${getFormattedTimeFromDate(lastDataUpdateDate)} ${timeZone}`;
};

export const handleSetsUpdate = (sets: string): string[] => {
    const updatedSets = sets.split(",").map(set => {
        return set.replace(/[^a-zA-Z0-9-_]/g, "").trim();
    });
    return updatedSets;
};

export const handleSetUpdate = (set: string): string => {
    return set.replace(/[^a-zA-Z0-9-_]/g, "").trim();
};

export const handleNetworkUpdate = (set: string): string => {
    return set.replace(/[^a-z0-9-_]/g, "").trim();
};

export const saveCollapseState = (tier: number, ids: Set<number>) => {
    const tierName = "collapse-states-" + tier;
    localStorage.setItem(tierName, JSON.stringify(Array.from(ids)));
};

export const loadCollapseState = (tier: number): Set<number> => {
    const tierName = "collapse-states-" + tier;
    const tierData = localStorage.getItem(tierName);
    return tierData
        ? new Set<number>(JSON.parse(tierData))
        : new Set<number>([]);
};

export const updateCollapseState = (
    set: Set<number>,
    id: number,
    show: boolean
): Set<number> => {
    if (show) {
        set.add(id);
    } else {
        set.delete(id);
    }
    return set;
};

export const updateCollapseTiersInCache = (
    client: ApolloClient<any>,
    productTier: Set<number> | undefined = undefined,
    projectTier: Set<number> | undefined = undefined
) => {
    const currentCache = client.readQuery<StateData>({
        query: GET_LOCAL_STATE
    });
    if (currentCache) {
        client.writeQuery<StateData, StateData>({
            query: GET_LOCAL_STATE,
            data: {
                state: {
                    ...currentCache.state,
                    productTier: productTier
                        ? productTier
                        : currentCache.state.productTier,
                    projectTier: projectTier
                        ? projectTier
                        : currentCache.state.projectTier
                }
            }
        });
    }
};

export const getVariationCollapseState = (
    type: VariationCollapseState
): boolean => {
    const stateData = localStorage.getItem("variation-collapse-state-" + type);
    if (stateData) {
        return stateData === "true";
    }
    return true;
};

export const saveVariationCollapseState = (
    type: VariationCollapseState,
    value: boolean
) => {
    localStorage.setItem("variation-collapse-state-" + type, value.toString());
};

export const saveSorting = (sortingData: SortingState) => {
    const jsonString = JSON.stringify({
        sortingPriorities: sortingData.sortingPriorities,
        organizationSorting: sortingData.organizationSorting
    });
    localStorage.setItem("sorting-state", jsonString);
};

export const getSorting = (): SortingData | undefined => {
    const jsonString = localStorage.getItem("sorting-state");
    let sortingData: SortingData;
    if (jsonString !== null) {
        sortingData = JSON.parse(jsonString);
        return sortingData;
    }
    return undefined;
};

const isCompleted = (build: BatchBuild) => {
    return (
        build.state === BatchBuildState.Failed ||
        build.state === BatchBuildState.Completed ||
        build.state === BatchBuildState.Test ||
        build.state === BatchBuildState.Review
    );
};

export const checkBuildsStatus = (data: BatchBuildsData | undefined) => {
    let hasBuilds = false,
        hasRunning = false,
        latestCompleted: BatchBuild | undefined;

    if (data && data.builds && data.builds.length > 0) {
        hasBuilds = data.builds.some(build => isCompleted(build));

        const latestBuild = data.builds[0];
        latestCompleted = isCompleted(latestBuild) ? latestBuild : undefined;
        hasRunning = latestBuild.state === BatchBuildState.Started;
    }

    return {
        hasBuilds,
        hasRunning,
        latestCompleted
    };
};

export const checkBuildSelection = (buildJobsData: BuildJobData[]) => {
    for (let i = 0; i < buildJobsData.length; i++) {
        if (!buildJobsData[i].include) {
            return false;
        }
    }
    return true;
};

export const dateComparison = (first: string, second: string) => {
    if (first === second) {
        return 0;
    }
    const firstDate = new Date(first);
    const secondDate = new Date(second);
    return firstDate > secondDate ? 1 : -1;
};

export const injectDailyData = (
    sorted: Product[] | Project[] | Revision[] | BatchBuild[],
    dailyAnalytics: DailyAnalytics[],
    referenceType: string
) => {
    const inject = (
        element: Product | Project | Revision | BatchBuild,
        data: DailyAnalytics
    ) => {
        return {
            ...element,
            impressions: data.impressions,
            gameplay: data.gameplay,
            endScreen: data.endScreen
        };
    };

    for (let i = 0; i < dailyAnalytics.length; i++) {
        for (let j = 0; j < sorted.length; j++) {
            if (
                referenceType === "product" &&
                dailyAnalytics[i].product?.id === sorted[j].id
            ) {
                sorted[j] = inject(sorted[j], dailyAnalytics[i]);
            } else if (
                referenceType === "project" &&
                dailyAnalytics[i].project?.id === sorted[j].id
            ) {
                sorted[j] = inject(sorted[j], dailyAnalytics[i]);
            } else if (
                referenceType === "revision" &&
                dailyAnalytics[i].revision?.id === sorted[j].id
            ) {
                sorted[j] = inject(sorted[j], dailyAnalytics[i]);
            } else if (
                referenceType === "build" &&
                dailyAnalytics[i].build?.id === sorted[j].id
            ) {
                sorted[j] = inject(sorted[j], dailyAnalytics[i]);
            }
        }
    }
};

export const getEnvironment = () => {
    switch (process.env.REACT_APP_URL) {
        case "http://localhost:8000": {
            return ProjectEnvironment.Local;
        }
        case "https://development-backend.seepia.com": {
            return ProjectEnvironment.Development;
        }
        case "https://platform-backend.seepia.com": {
            return ProjectEnvironment.Staging;
        }
        case "https://playables-backend.seepia.com": {
            return ProjectEnvironment.Production;
        }
        default:
            return ProjectEnvironment.Development;
    }
};

export const validateJson = (data: string | undefined) => {
    if (!data) {
        return false;
    }
    try {
        const validatedData = JSON.parse(data);
        console.log("[DEBUG] validated ", validatedData);
        return true;
    } catch (error) {
        console.log("[DEBUG] error: ", error);
        return false;
    }
};

export const validateBuildSetName = (
    name: string | undefined,
    canBeEmpty = false
) => {
    if (!name && !canBeEmpty) {
        return false;
    }
    /*eslint no-useless-escape: "off"*/
    const regex = /^[a-zA-ZäÄöÖåÅ 0-9_\-.&()\[\]]*$/gm;
    const isValid = name?.match(regex);
    return isValid !== null;
};

export const validateUuid = (uuid: string) => {
    if (!uuid) {
        return false;
    }
    const uuidRegex =
        /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    return uuidRegex.test(uuid);
};

export const validateEmail = (email: string) => {
    const pattern = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
    return pattern.test(email);
};

export const checkBuildDataVersion = (
    newVersion: number,
    oldVersion: number
) => {
    if (newVersion !== oldVersion + 1) {
        throw new Error("Build data out of sync");
    }
};

export const getGitSupportedName = (name: string | undefined) => {
    if (!name) {
        return false;
    }
    return name
        .replace(/[ ]/g, "-")
        .replace(/[^a-zA-Z0-9-_]/g, "")
        .replace("--", "-");
};

export const getCurrentTechStackSelectionByType = (
    type: TechStackType,
    techStack: TechStackConfig
) => {
    switch (type) {
        case TechStackType.Frameworks:
            return techStack.frameworks.selection.map(element => {
                return element.name;
            });
        case TechStackType.Sounds:
            return techStack.sounds.selection.map(element => {
                return element.name;
            });
        case TechStackType.Particles:
            return techStack.particles.selection.map(element => {
                return element.name;
            });
        case TechStackType.Physics:
            return techStack.physics.selection.map(element => {
                return element.name;
            });
        case TechStackType.Animations:
            return techStack.animations.selection.map(element => {
                return element.name;
            });
        case TechStackType.Networks:
            return techStack.networks.selection.map(element => {
                return element.name;
            });
        default:
            return [];
    }
};

export const updateSearchTermInCache = (
    client: ApolloClient<any>,
    search?: string
) => {
    if (search === "") {
        search = undefined;
    }
    const currentCache = client.readQuery<StateData>({
        query: GET_LOCAL_STATE
    });
    if (currentCache && currentCache.state) {
        client.writeQuery<StateData, StateData>({
            query: GET_LOCAL_STATE,
            data: {
                state: {
                    ...currentCache.state,
                    searchTerm: search
                }
            }
        });
    }
};

export const updateRevisionPartially = async (
    client: ApolloClient<any>,
    revision: Revision,
    partialUpdateRevision: MutationFunction<
        PartialRevisionData,
        PartialRevisionVariables
    >,
    newBuildData?: Types,
    operation?: string
) => {
    if (!newBuildData) {
        return;
    }
    const startTime = performance.now();
    if (revision && newBuildData) {
        try {
            const result = await partialUpdateRevision({
                variables: {
                    revisionId: revision.id,
                    partialData: newBuildData,
                    operation: operation
                }
            });
            if (result.data?.partialUpdateRevision.version) {
                const version = result.data?.partialUpdateRevision.version;
                checkBuildDataVersion(version, revision.buildData.version);
                const operation = result.data?.partialUpdateRevision.operation;
                let updatedData = undefined;
                if (Object.keys(newBuildData).length > 1) {
                    switch (operation) {
                        case "update": {
                            updatedData = {
                                ...revision.buildData,
                                version: version,
                                ...newBuildData
                            };
                            break;
                        }
                        default:
                            console.log(
                                `[DEBUG] operation ${operation} not supported for multiple key updates`
                            );
                            break;
                    }
                } else {
                    const keyToUpdate = Object.keys(newBuildData)[0];
                    let updatedPartialData = revision.buildData[keyToUpdate]
                        ? [...revision.buildData[keyToUpdate]]
                        : [];
                    if (!newBuildData) {
                        return;
                    }
                    switch (operation) {
                        case "update": {
                            if (
                                keyToUpdate === "placeholders" ||
                                (keyToUpdate === "networks" &&
                                    // @ts-ignore
                                    newBuildData[keyToUpdate].length > 1) ||
                                (keyToUpdate === "localizations" &&
                                    // @ts-ignore
                                    newBuildData[keyToUpdate].length > 1)
                            ) {
                                // @ts-ignore
                                updatedPartialData = newBuildData[keyToUpdate];
                            } else {
                                updatedPartialData = updatedPartialData.map(
                                    (element: any) =>
                                        element.id ===
                                        // @ts-ignore
                                        newBuildData[keyToUpdate][0].id
                                            ? // @ts-ignore
                                              newBuildData[keyToUpdate][0]
                                            : element
                                );
                            }
                            break;
                        }
                        case "delete": {
                            updatedPartialData = updatedPartialData.filter(
                                (element: any) =>
                                    element.id !==
                                    // @ts-ignore
                                    newBuildData[keyToUpdate][0].id
                            );
                            break;
                        }
                        case "new": {
                            if (
                                keyToUpdate === "localizations" ||
                                keyToUpdate === "legacyLocalizations"
                            )
                                updatedPartialData = updatedPartialData.filter(
                                    (element: any) =>
                                        element.localization !==
                                        // @ts-ignore
                                        newBuildData[keyToUpdate][0]
                                            .localization
                                );
                            const newId =
                                result.data?.partialUpdateRevision.newId;

                            if (newId === undefined) {
                                console.log("[DEBUG] MISSING NEW ID");
                                return;
                            }

                            // @ts-ignore
                            newBuildData[keyToUpdate][0].id = newId;
                            updatedPartialData = [
                                ...updatedPartialData,
                                // @ts-ignore
                                newBuildData[keyToUpdate][0]
                            ];
                            break;
                        }
                        default:
                            console.log(
                                "[DEBUG] not implemented yet ",
                                operation
                            );
                            break;
                    }
                    updatedData = {
                        ...revision.buildData,
                        version: version,
                        [keyToUpdate]: updatedPartialData
                    };
                }
                if (updatedData) {
                    client.writeQuery<RevisionData, RevisionVariables>({
                        query: GET_REVISION,
                        data: {
                            revision: {
                                ...revision,
                                buildData: updatedData
                            }
                        }
                    });
                }
            }
        } catch (error) {
            // @ts-ignore
            if (error.message === "Build data out of sync") {
                const result = await client.refetchQueries({
                    include: ["getRevision"]
                });

                if (result.length > 0) {
                    updateNotificationState(client, false, true, {
                        success: false,
                        header: "Data out of sync",
                        message: "Data has been updated",
                        delay: 3000
                    });
                }
            }
            console.log("[DEBUG] error ", error);
        }
    }
    console.log("[DEBUG] Took: ", performance.now() - startTime);
};

export const updateNotificationState = (
    client: ApolloClient<any>,
    showLoading = false,
    showToast = false,
    toastData: ToastData | undefined = undefined
) => {
    const currentCache = client.readQuery<NotificationStateData>({
        query: GET_LOCAL_NOTIFICATION_STATE
    });
    if (currentCache && currentCache.notificationState) {
        client.writeQuery<NotificationStateData, NotificationStateData>({
            query: GET_LOCAL_NOTIFICATION_STATE,
            data: {
                notificationState: {
                    ...currentCache.notificationState,
                    showLoading: showLoading,
                    showToast: showToast,
                    toastData: toastData
                }
            }
        });
    }
};

export const updatePaginatorState = (
    client: ApolloClient<any>,
    currentPage: number
) => {
    const currentCache = client.readQuery<PaginatorStateData>({
        query: GET_LOCAL_PAGINATOR_STATE
    });
    if (currentCache && currentCache.paginatorState) {
        client.writeQuery<PaginatorStateData, PaginatorStateData>({
            query: GET_LOCAL_PAGINATOR_STATE,
            data: {
                paginatorState: {
                    ...currentCache.paginatorState,
                    currentPage: currentPage
                }
            }
        });
    }
};

export const updateEditPanelState = (
    client: ApolloClient<any>,
    newState: EditPanelState
) => {
    const currentCache = client.readQuery<EditPanelStateData>({
        query: GET_LOCAL_EDITPANEL_STATE
    });
    if (currentCache && currentCache.editPanelState) {
        client.writeQuery<EditPanelStateData, EditPanelStateData>({
            query: GET_LOCAL_EDITPANEL_STATE,
            data: {
                editPanelState: newState
            }
        });
    }
};

export const updateSortingState = (
    client: ApolloClient<any>,
    index: number,
    newMode: SortingMode
) => {
    const currentCache = client.readQuery<SortingStateData>({
        query: GET_LOCAL_SORTING_STATE
    });

    if (currentCache) {
        const newPriorities = [...currentCache.sortingState.sortingPriorities];
        if (index < 0) {
            let lastMode = newPriorities[0] || SortingMode.None;
            let count = 0;
            for (let i = 0; i < newPriorities.length; i++) {
                count = newPriorities[i] === lastMode ? count + 1 : count;
                if (newPriorities[i] === SortingMode.None) {
                    newPriorities[i] = newMode;
                } else if (count >= 2 && newMode !== newPriorities[i]) {
                    newPriorities[i] = newMode;
                }
                lastMode = newPriorities[i];
            }
        } else if (newMode === SortingMode.None) {
            const oldMode = newPriorities[index];
            for (let i = newPriorities.length - 1; i >= 0; i--) {
                if (newPriorities[i] === oldMode) {
                    newPriorities.splice(i, 1);
                }
            }

            const copyElement =
                newPriorities.length > 0
                    ? newPriorities[newPriorities.length - 1]
                    : SortingMode.None;
            const copyCount = 3 - newPriorities.length;
            for (let index = 0; index < copyCount; index++) {
                newPriorities.push(copyElement);
            }
        } else {
            const oldMode = newPriorities[index];
            for (let i = 0; i < newPriorities.length; i++) {
                if (newPriorities[i] === oldMode) {
                    newPriorities[i] = newMode;
                }
            }
        }

        if (currentCache && currentCache.sortingState) {
            const newState = {
                ...currentCache.sortingState,
                sortingPriorities: newPriorities
            };
            saveSorting(newState);
            client.writeQuery<SortingStateData, SortingStateData>({
                query: GET_LOCAL_SORTING_STATE,
                data: {
                    sortingState: newState
                }
            });
        }
    }
};

export const updateDevelopmentSortingState = (
    client: ApolloClient<any>,
    organizationSortingEnabled: boolean
) => {
    const currentCache = client.readQuery<SortingStateData>({
        query: GET_LOCAL_SORTING_STATE
    });

    if (currentCache) {
        const newState = {
            ...currentCache.sortingState,
            organizationSorting: organizationSortingEnabled
        };
        saveSorting(newState);
        client.writeQuery<SortingStateData, SortingStateData>({
            query: GET_LOCAL_SORTING_STATE,
            data: {
                sortingState: newState
            }
        });
    }
};

export const replaceSortingState = (
    client: ApolloClient<any>,
    newPriorities: SortingMode[]
) => {
    const currentCache = client.readQuery<SortingStateData>({
        query: GET_LOCAL_SORTING_STATE
    });

    if (currentCache && currentCache.sortingState) {
        const newState = {
            ...currentCache.sortingState,
            sortingPriorities: newPriorities
        };
        saveSorting(newState);
        client.writeQuery<SortingStateData, SortingStateData>({
            query: GET_LOCAL_SORTING_STATE,
            data: {
                sortingState: newState
            }
        });
    }
};

export const resetSortingState = (client: ApolloClient<any>) => {
    saveSorting(initialSortingState);
    client.writeQuery<SortingStateData, SortingStateData>({
        query: GET_LOCAL_SORTING_STATE,
        data: {
            sortingState: initialSortingState
        }
    });
};

export const sortItems = (
    first: Product | Project | Revision,
    second: Product | Project | Revision,
    sortMode: SortingMode,
    sortByOrganization = false
) => {
    let sortValue = 0;

    switch (sortMode) {
        case SortingMode.NameAscending:
            sortValue = first.name.localeCompare(second.name);
            break;
        case SortingMode.NameDescending:
            sortValue = second.name.localeCompare(first.name);
            break;
        case SortingMode.DateAscending:
            sortValue = dateComparison(second.dateUpdated, first.dateUpdated);
            break;
        case SortingMode.DateDescending:
            sortValue = dateComparison(first.dateUpdated, second.dateUpdated);
            break;
        case SortingMode.CtrAscending: {
            const valueFirst =
                !first.gameplay ||
                !first.endScreen ||
                !first.impressions ||
                first.impressions <= 0
                    ? 0
                    : (first.gameplay + first.endScreen) / first.impressions;
            const valueSecond =
                !second.gameplay ||
                !second.endScreen ||
                !second.impressions ||
                second.impressions <= 0
                    ? 0
                    : (second.gameplay + second.endScreen) / second.impressions;
            sortValue = valueFirst - valueSecond;
            break;
        }
        case SortingMode.CtrDescending: {
            const valueFirst =
                !first.gameplay ||
                !first.endScreen ||
                !first.impressions ||
                first.impressions <= 0
                    ? 0
                    : (first.gameplay + first.endScreen) / first.impressions;
            const valueSecond =
                !second.gameplay ||
                !second.endScreen ||
                !second.impressions ||
                second.impressions <= 0
                    ? 0
                    : (second.gameplay + second.endScreen) / second.impressions;
            sortValue = valueSecond - valueFirst;
            break;
        }
        case SortingMode.UserCtrAscending: {
            const valueFirst =
                !first.userGameplay ||
                !first.userEndScreen ||
                !first.userImpressions ||
                first.userImpressions <= 0
                    ? 0
                    : (first.userGameplay + first.userEndScreen) /
                      first.userImpressions;
            const valueSecond =
                !second.userGameplay ||
                !second.userEndScreen ||
                !second.userImpressions ||
                second.userImpressions <= 0
                    ? 0
                    : (second.userGameplay + second.userEndScreen) /
                      second.userImpressions;
            sortValue = valueFirst - valueSecond;
            break;
        }
        case SortingMode.UserCtrDescending: {
            const valueFirst =
                !first.userGameplay ||
                !first.userEndScreen ||
                !first.userImpressions ||
                first.userImpressions <= 0
                    ? 0
                    : (first.userGameplay + first.userEndScreen) /
                      first.userImpressions;
            const valueSecond =
                !second.userGameplay ||
                !second.userEndScreen ||
                !second.userImpressions ||
                second.userImpressions <= 0
                    ? 0
                    : (second.userGameplay + second.userEndScreen) /
                      second.userImpressions;
            sortValue = valueSecond - valueFirst;
            break;
        }
        case SortingMode.ImpressionsAscending:
            sortValue = (first.impressions || 0) - (second.impressions || 0);
            break;
        case SortingMode.ImpressionsDescending:
            sortValue = (second.impressions || 0) - (first.impressions || 0);
            break;
        case SortingMode.UserImpressionsAscending:
            sortValue =
                (first.userImpressions || 0) - (second.userImpressions || 0);
            break;
        case SortingMode.UserImpressionsDescending:
            sortValue =
                (second.userImpressions || 0) - (first.userImpressions || 0);
            break;
        default:
            break;
    }

    if (
        sortByOrganization &&
        "organization" in first &&
        "organization" in second
    ) {
        if (first.organization.name !== second.organization.name) {
            sortValue = first.organization.name.localeCompare(
                second.organization.name
            );
        }
    }
    return sortValue;
};

export const updateMutatorState = (
    client: ApolloClient<any>,
    showMeta: boolean
) => {
    const currentCache = client.readQuery<MutatorStateData>({
        query: GET_LOCAL_MUTATOR_STATE
    });

    if (currentCache) {
        const newState = {
            ...currentCache.mutatorState,
            showMeta: showMeta
        };
        client.writeQuery<MutatorStateData, MutatorStateData>({
            query: GET_LOCAL_MUTATOR_STATE,
            data: {
                mutatorState: newState
            }
        });
    }
};

// EditPanel Build Validation Helpers
export const validateBuild = (
    build: Build,
    config: any
): EditPanelValidation => {
    if (!config) {
        return {
            valid: false,
            message: "Missing configuration"
        };
    }
    if (!build.include) {
        return {
            valid: true
        };
    }
    const sizeTarget = build.size;
    if (!config.networks || config.networks.length === 0) {
        return {
            valid: false,
            message: "Missing networks configuration"
        };
    }
    for (let i = 0; i < config.networks.length; i++) {
        if (config.networks[i].sizeTargets.includes(sizeTarget)) {
            return { valid: true };
        }
    }
    return {
        valid: false,
        message: `No network selected with the size target of ${sizeTarget}`
    };
};

export const validateNetwork = (
    network: Network,
    config: any
): EditPanelValidation => {
    if (!config) {
        return {
            valid: false,
            message: "Missing configuration"
        };
    }
    const sizeTargets = network.sizeTargets;
    if (sizeTargets.length === 0) {
        return {
            valid: true
        };
    }
    if (!config.builds || config.builds.length === 0) {
        return {
            valid: false,
            message: "Missing builds configuration"
        };
    }
    for (let i = 0; i < config.builds.length; i++) {
        if (sizeTargets.includes(config.builds[i].size)) {
            return { valid: true };
        }
    }
    return {
        valid: false,
        message: `None of the configured builds have any the size target(s) (${sizeTargets}) selected`
    };
};

export const validateBuildConfig = (
    config: any
): { base?: string; network?: string }[] => {
    const warnings: { base?: string; network?: string }[] = [];
    if (!config.builds || config.builds.length === 0) {
        warnings.push({
            base: undefined,
            network: undefined
        });
    } else {
        for (let i = 0; i < config.builds.length; i++) {
            const element = config.builds[i];
            if (!element.include) {
                continue;
            }
            for (let j = 0; j < config.networks.length; j++) {
                if (config.networks[j].sizeTargets.length === 0) {
                    continue;
                }
                if (!config.networks[j].sizeTargets.includes(element.size)) {
                    warnings.push({
                        base: element.name,
                        network: config.networks[j].network
                    });
                }
            }
        }
    }
    return warnings;
};
