import React, { FC, useState } from "react";
import { Button, Card, InputGroup } from "react-bootstrap";
import { Network, PlatformConfigData } from "../../models/types";
import { VariationItemProps } from "../../models/common";
import { SimpleDropdown } from "../dropdowns/SimpleDropdown";
import { useQuery } from "@apollo/client";
import { GET_PLATFORM_CONFIG } from "../../graphql/queries";
import "./variationItems.scss";

interface NetworkProps extends VariationItemProps {
    defaultValue: Network;
}

export const NewNetworkItem: FC<NetworkProps> = ({
    defaultValue,
    revision,
    callback
}) => {
    const { data: { getPlatformConfig } = {} } =
        useQuery<PlatformConfigData>(GET_PLATFORM_CONFIG);
    const [network, setNetwork] = useState(defaultValue);
    const [small, setSmall] = useState(defaultValue.sizeTargets.includes(2));
    const [medium, setMedium] = useState(defaultValue.sizeTargets.includes(3));
    const [big, setBig] = useState(defaultValue.sizeTargets.includes(5));

    const updateNetworkIncludes = (include: boolean, size = -1) => {
        const sizeTargets = network.sizeTargets;
        if (include) {
            sizeTargets.push(size);
        } else {
            const index = sizeTargets.indexOf(size);
            if (index > -1) {
                sizeTargets.splice(index, 1);
            }
        }
    };

    const updateTarget = (size: number, checked: boolean) => {
        switch (size) {
            case 2:
                setSmall(checked);
                break;
            case 3:
                setMedium(checked);
                break;
            case 5:
                setBig(checked);
                break;
        }
        updateNetworkIncludes(checked, size);
    };

    const unusedNetworks = (): string[] => {
        const unusedNetworks: string[] = [];
        if (getPlatformConfig && getPlatformConfig.supportedNetworks) {
            for (
                let i = 0;
                i < getPlatformConfig.supportedNetworks.length;
                i++
            ) {
                let isUsed = false;
                const network = getPlatformConfig.supportedNetworks[i];
                for (let j = 0; j < revision?.buildData.networks.length; j++) {
                    const element = revision?.buildData.networks[j];
                    if (element.network === network) {
                        isUsed = true;
                        break;
                    }
                }
                if (!isUsed) {
                    unusedNetworks.push(network);
                }
            }
        }
        return unusedNetworks;
    };

    if (unusedNetworks().length === 0)
        return (
            <Card className="no-border fullSize px-2">
                <Card.Body className="m-0 p-2 d-flex flex-column">
                    <span className="text-secondary">
                        No more networks available
                    </span>
                </Card.Body>
            </Card>
        );

    return (
        <Card className="no-border fullSize px-2">
            <Card.Body className="m-0 p-2 d-flex flex-column">
                <InputGroup className="mb-2">
                    <InputGroup.Text>Network name</InputGroup.Text>
                    <SimpleDropdown
                        header={
                            network.network !== ""
                                ? network.network
                                : "Select Network"
                        }
                        choices={unusedNetworks()}
                        updateSelection={(selection: string) =>
                            setNetwork({
                                ...network,
                                network: selection
                            })
                        }
                    />
                </InputGroup>
                <InputGroup className="m-0 p-0 mb-4">
                    <InputGroup.Text>2MB</InputGroup.Text>
                    <InputGroup.Checkbox
                        title="Include 2MB size in next the Build set"
                        aria-label="Include 2MB size in next the Build set"
                        checked={small}
                        onChange={(event: any) => {
                            updateTarget(2, event.target.checked);
                        }}
                    />
                    <InputGroup.Text className="ms-2">3MB</InputGroup.Text>
                    <InputGroup.Checkbox
                        aria-label="Include 3MB size in next the build set"
                        checked={medium}
                        onChange={(event: any) => {
                            updateTarget(3, event.target.checked);
                        }}
                    />

                    <InputGroup.Text className="ms-2">5MB</InputGroup.Text>
                    <InputGroup.Checkbox
                        title="Include 5MB size in next the Build set"
                        aria-label="Include 5MB size in next the Build set"
                        checked={big}
                        onChange={(event: any) => {
                            updateTarget(5, event.target.checked);
                        }}
                    />
                </InputGroup>
                <Button onClick={() => (callback ? callback(network) : null)}>
                    Save
                </Button>
            </Card.Body>
        </Card>
    );
};
