import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import {
    useBlocker,
    useLocation,
    useNavigate,
    useNavigationType,
    useParams
} from "react-router-dom";
import { BackButton } from "../../simple/BackButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpRightAndDownLeftFromCenter } from "@fortawesome/free-solid-svg-icons";
import {
    AditorCreateInput,
    AditorCreateVariables,
    AditorInitQueryData,
    AditorMessage,
    AditorSceneVariables,
    AditorUpdateInput,
    AditorUpdateVariables,
    CreateAditorSceneData,
    CreateAditorSceneResult,
    DeleteAditorSceneData,
    UpdateAditorSceneData
} from "../../../models/aditor";
import { useMutation, useQuery } from "@apollo/client";
import {
    ADITOR_CREATE_SCENE,
    ADITOR_DELETE_SCENE,
    ADITOR_UPDATE_SCENE
} from "../../../graphql/mutations";
import { ADITOR_INIT_QUERY } from "../../../graphql/queries";
import { AditorOptimizingModal } from "../../modals/aditor/AditorOptimizingModal";
import { useLocalState } from "../../../graphql/hooks";
import { getSceneAsBase64 } from "../../../common/AditorHelpers";
import { AlertModal } from "../../modals/AlertModal";
import styles from "../../panels/EditPanel.module.scss";

const AditorViewImplementation = () => {
    const { revisionId } = useParams<{
        revisionId: string;
    }>();
    const navigate = useNavigate();
    const location = useLocation();
    const navigationType = useNavigationType();
    const { developerMode } = useLocalState();
    const [aditorReady, updateAditorReady] = useState(false);
    const [htmlContent, setHtmlContent] = useState<string>("");
    const [showOptimizingModal, updateShowOptimizingModal] = useState(false);
    const [fullScreen, updateFullScreen] = useState(false);
    const [isBlocking, setIsBlocking] = useState(false);

    const aditorReference = useRef<any>(null);
    const fullScreenReference = useRef(false);

    const {
        loading: aditorInitLoading,
        error: aditorInitError,
        data
    } = useQuery<AditorInitQueryData, AditorSceneVariables>(ADITOR_INIT_QUERY, {
        variables: { revisionId: Number(revisionId) },
        fetchPolicy: "network-only"
    });

    const [createSceneMutation] = useMutation<
        CreateAditorSceneData,
        AditorCreateVariables
    >(ADITOR_CREATE_SCENE);

    const [updateSceneMutation] = useMutation<
        UpdateAditorSceneData,
        AditorUpdateVariables
    >(ADITOR_UPDATE_SCENE);

    const [deleteSceneMutation] = useMutation<
        DeleteAditorSceneData,
        AditorSceneVariables
    >(ADITOR_DELETE_SCENE);

    const handleKeydown = useCallback((event: any) => {
        if (fullScreenReference.current && event.key === "Escape") {
            toggleFullscreen(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (aditorReady) {
            const iframe = aditorReference.current;
            if (!iframe) {
                console.log("[DEBUG] iframe not found stopping eventlisteners");
                return;
            }
            const iframeDocument = iframe.contentWindow.document;
            iframeDocument.addEventListener("keydown", (event: any) =>
                handleKeydown(event)
            );
            window.addEventListener("keydown", (event: any) =>
                handleKeydown(event)
            );
            return () => {
                iframeDocument.removeEventListener("keydown", handleKeydown);
                window.removeEventListener("keydown", handleKeydown);
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [aditorReady]);

    useEffect(() => {
        console.log("[DEBUG] data ", data);
        if (aditorReady && !aditorInitLoading && !aditorInitError && data) {
            console.log("[DEBUG] data.aditorLoadScene ", data.aditorLoadScene);
            console.log(
                "[DEBUG] data.aditorLoadScene.data ",
                data.aditorLoadScene.data
            );
            const scopes = [developerMode ? "full" : "client"];
            if (data.aditorLoadScene && data.aditorLoadScene.data) {
                getSceneAsBase64(data.aditorLoadScene.data)
                    .then(result => {
                        const initMessage: AditorMessage = {
                            type: "init",
                            data: {
                                user: {
                                    scopes: scopes
                                },
                                data: result,
                                templates: data.aditorTemplates
                            }
                        };
                        console.log(
                            "[DEBUG] sendInitMessage with scene ",
                            initMessage
                        );
                        sendMessageToIframe(initMessage);
                        // if (result) {
                        //     updateLoadedScene(true);
                        // }
                    })
                    .catch(error => {
                        console.error("Error loading scene: ", error);
                    });
            } else {
                const initMessage: AditorMessage = {
                    type: "init",
                    data: {
                        user: {
                            scopes: scopes
                        },
                        data: undefined,
                        templates: data.aditorTemplates
                    }
                };
                console.log(
                    "[DEBUG] sendInitMessage without scene ",
                    initMessage
                );
                sendMessageToIframe(initMessage);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [aditorInitLoading, aditorInitError, aditorReady]);

    useEffect(() => {
        const fetchHtmlFromS3 = async () => {
            try {
                // Official Aditor
                const response = await fetch(
                    "https://seepia-review.s3.eu-north-1.amazonaws.com/aditor/Aditor.html"
                );
                // Local test build
                // const response = await fetch(
                //     "https://seepia-review.s3.eu-north-1.amazonaws.com/seepia/experimental/aditor/index.html"
                // );
                // Test index
                // const response = await fetch(
                //     "https://seepia-review.s3.eu-north-1.amazonaws.com/seepia/experimental/aditor/test_iframe.html"
                // );
                if (!response.ok) {
                    throw new Error("Failed to fetch the HTML file from S3");
                }

                const htmlData = await response.text();
                setHtmlContent(htmlData);
            } catch (error) {
                console.error("Error fetching the file:", error);
            }
        };

        fetchHtmlFromS3();
    }, []);

    useEffect(() => {
        window.addEventListener("message", handleAditorMessages);
        return () => {
            window.removeEventListener("message", handleAditorMessages);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const sendCreatedSceneToIFrame = async (
        sceneData: CreateAditorSceneResult
    ) => {
        try {
            const base64String = await getSceneAsBase64(sceneData.data);
            sendMessageToIframe({
                type: "createdScene",
                data: base64String
            });
        } catch (error) {
            console.log("[DEBUG] sendCreatedSceneToIFrame with error ", error);
        }
    };

    const createScene = (template: string) => {
        const input: AditorCreateInput = {
            revisionId: Number(revisionId),
            template: template
        };
        createSceneMutation({
            variables: {
                input: input
            }
        })
            .then(result => {
                if (result.data) {
                    sendCreatedSceneToIFrame(result.data.aditorCreateScene);
                    // updateLoadedScene(true);
                } else {
                    console.log(
                        "[DEBUG] unexpected result data from createSceneMutation ",
                        result
                    );
                }
            })
            .catch(error =>
                console.log(
                    "[DEBUG] createSceneMutation failed with error ",
                    error
                )
            );
    };

    const deleteScene = () => {
        deleteSceneMutation({
            variables: {
                revisionId: Number(revisionId)
            }
        })
            .then(result => {
                console.log("[DEBUG] delete scene result ", result);
                if (result.data) {
                    sendMessageToIframe({
                        type: "deletedScene",
                        data: {
                            success: true
                        }
                    });
                } else {
                    console.log(
                        "[DEBUG] unexpected result data from deleteSceneMutation ",
                        result
                    );
                    sendMessageToIframe({
                        type: "deletedScene",
                        data: {
                            success: false,
                            message:
                                "[DEBUG] unexpected result data from deleteSceneMutation " +
                                result
                        }
                    });
                }
                // updateLoadedScene(false);
            })
            .catch(error => {
                console.log(
                    "[DEBUG] deleteSceneMutation failed with error ",
                    error
                );
                sendMessageToIframe({
                    type: "deletedScene",
                    data: {
                        success: false,
                        message: error as string
                    }
                });
            });
    };

    const updateScene = (sceneAsBase64: string) => {
        const input: AditorUpdateInput = {
            revisionId: Number(revisionId),
            data: sceneAsBase64
        };
        updateSceneMutation({
            variables: {
                input: input
            }
        })
            .then(result => {
                if (result.data && result.data.aditorUpdateScene) {
                    sendMessageToIframe({
                        type: "updatedScene",
                        data: {
                            success: true
                        }
                    });
                } else {
                    console.log(
                        "[DEBUG] unexpected result data from updateSceneMutation ",
                        result
                    );
                    sendMessageToIframe({
                        type: "updatedScene",
                        data: {
                            success: false,
                            message:
                                "unexpected result data from updateSceneMutation"
                        }
                    });
                }
            })
            .catch(error => {
                console.log(
                    "[DEBUG] updateSceneMutation failed with error ",
                    error
                );
                sendMessageToIframe({
                    type: "updatedScene",
                    data: {
                        success: false,
                        message: error as string
                    }
                });
            });
    };

    const toggleFullscreen = (newState: boolean) => {
        updateFullScreen(newState);
        fullScreenReference.current = newState;
        sendMessageToIframe({
            type: "fullscreen",
            data: newState
        });
    };

    const handleAditorMessages = (event: MessageEvent) => {
        if (event.source !== aditorReference.current?.contentWindow) {
            return;
        }
        try {
            const messageData = event.data;
            switch (messageData.type) {
                case "aditorReady": {
                    updateAditorReady(true);
                    break;
                }
                case "createScene": {
                    createScene(messageData.data);
                    break;
                }
                case "deleteScene": {
                    deleteScene();
                    break;
                }
                case "updateScene": {
                    updateScene(messageData.data);
                    break;
                }
                case "fullscreen": {
                    toggleFullscreen(messageData.data);
                    break;
                }
                case "dirty": {
                    if (typeof messageData.data !== "boolean") {
                        console.log(
                            "[DEBUG] messageData.data contained wrong value type ",
                            messageData.data
                        );
                        break;
                    }
                    setIsBlocking(messageData.data);
                    break;
                }
                default: {
                    console.log(
                        "[DEBUG] message type: ",
                        messageData.type,
                        " not implemented yet"
                    );
                    break;
                }
            }
        } catch (error) {
            console.error(
                "[ERROR] handleAditorMessages failed with error: ",
                error
            );
        }
    };

    const sendMessageToIframe = (message: AditorMessage) => {
        if (aditorReference) {
            aditorReference.current?.contentWindow?.postMessage(message, "*");
        }
    };

    const blocker = useBlocker(
        ({ currentLocation, nextLocation }) =>
            isBlocking && currentLocation.pathname !== nextLocation.pathname
    );
    return (
        <>
            <div className={fullScreen ? "aditorViewFull" : "aditorViewSmall"}>
                {!fullScreen ? (
                    <div className="p-1 border-bottom border-inverse-25 d-flex">
                        <div className={styles.backButton}>
                            <BackButton
                                hasHistory={
                                    !(location.key && navigationType === "POP")
                                }
                                goBack={() => navigate(-1)}
                                size={"lg"}
                            />
                        </div>
                        <div className="float-end ms-auto pe-3 pt-3">
                            <Button
                                className={styles.maximizeButton}
                                onClick={() => toggleFullscreen(true)}
                                title="Aditor Fullscreen mode"
                            >
                                <FontAwesomeIcon
                                    size="1x"
                                    icon={faUpRightAndDownLeftFromCenter}
                                />
                            </Button>
                        </div>
                    </div>
                ) : null}
                <iframe
                    id="aditor-iframe"
                    ref={aditorReference}
                    title="Aditor"
                    srcDoc={htmlContent}
                    width="100%"
                    height="100%"
                    className="border-0"
                />
            </div>
            {showOptimizingModal ? (
                <AditorOptimizingModal
                    revisionId={Number(revisionId)}
                    onClose={() => updateShowOptimizingModal(false)}
                />
            ) : null}
            {blocker.state === "blocked" ? (
                <AlertModal
                    header={<h5>Are you sure you want to leave?</h5>}
                    component="You have unsaved changes."
                    visible={() => blocker.reset()}
                    footer={
                        <Button
                            variant="warning"
                            className="m-2"
                            onClick={() => blocker.proceed()}
                        >
                            Leave
                        </Button>
                    }
                />
            ) : null}
        </>
    );
};

export const AditorView = React.memo(AditorViewImplementation);
