import React, { FC, SyntheticEvent } from "react";
import { Badge, Button, Col, ListGroup, Row } from "react-bootstrap";
import {
    BatchBuild,
    BatchBuildState,
    BuildType,
    CancelBatchBuildData,
    CancelBatchBuildVariables,
    ReleaseType
} from "../../models/types";
import { getFormattedTime } from "../../common/Helpers";
import { DailyBadges } from "./analytics/DailyBadges";
import { useMutation } from "@apollo/client";
import { CANCEL_BUILD } from "../../graphql/mutations";
import { GET_RESULTS } from "../../graphql/queries";
import { StepProgressBar } from "./pipeline/StepProgressBar";
import { useLocalAnalyticsState } from "../../graphql/hooks";
import { UserQueryBadges } from "./analytics/UserQueryBadges";
import { faCheck, faFilter, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cx from "classnames";
import { useNavigate } from "react-router-dom";

interface Props {
    developerMode: boolean;
    revisionType: ReleaseType;
    object: BatchBuild;
    linkPath: string;
    isLatestBuild: boolean;
    openErrorLogs: (buildId: number) => void;
    updateBuildState: (buildId: number, newState: BatchBuildState) => void;
    isInFunnel: boolean;
    toggleFunnelSelection: () => void;
}

const getBadgeVariation = (type: any) => {
    switch (type) {
        case BatchBuildState.Started:
        case BatchBuildState.JobCreated:
        case BatchBuildState.JobStarted:
        case BatchBuildState.JobBuilt:
        case BatchBuildState.JobCleaned:
            return "info";
        case BatchBuildState.Completed:
            return "primary";
        case BatchBuildState.Review:
            return "review";
        case BatchBuildState.Failed:
            return "danger";
        case BatchBuildState.Test:
            return "test";
        case BatchBuildState.Stopped:
            return "secondary";
        case "warning":
            return "warning";
        case "latest":
            return "success";
        default:
            console.log("[DEBUG] Unknown type ", type);
            break;
    }
};

export const HistoryItem: FC<Props> = ({
    linkPath,
    object,
    developerMode,
    revisionType,
    updateBuildState,
    openErrorLogs,
    isLatestBuild,
    isInFunnel,
    toggleFunnelSelection
}) => {
    const navigate = useNavigate();
    const { analyticsEnabled, analyticsFunnelSelectionMode } =
        useLocalAnalyticsState();
    const buildInfo = `Build ${object.id}`;
    const formattedDate = ` ${getFormattedTime(object.dateCreated)}`;

    const [cancelBuild] = useMutation<
        CancelBatchBuildData,
        CancelBatchBuildVariables
    >(CANCEL_BUILD);

    const startCancelJob = async () => {
        try {
            await cancelBuild({
                variables: { buildId: Number(object.id) },
                refetchQueries: [
                    {
                        query: GET_RESULTS,
                        variables: {
                            revisionId: object.revision.id,
                            clientTest:
                                !developerMode &&
                                revisionType === ReleaseType.Development
                        }
                    }
                ]
            });
        } catch (error) {
            console.log("[DEBUG] startCancelJob error ", error);
        }
    };

    return (
        <ListGroup.Item
            variant="primary"
            style={{
                borderRadius: "4px",
                paddingTop: "0.5rem",
                paddingBottom: "0.5rem",
                paddingRight: "0.5rem",
                paddingLeft: "0.8rem"
            }}
            className="mx-2 my-1 mouseHover"
            onClick={() => {
                if (
                    object.state === BatchBuildState.Failed ||
                    object.state === BatchBuildState.Stopped
                ) {
                    openErrorLogs(Number(object.id));
                } else if (object.state >= BatchBuildState.Completed) {
                    navigate(linkPath);
                }
            }}
        >
            <Row className="d-flex align-items-center g-0">
                <Col className="d-flex col-3 justify-content-start">
                    <span className="bread-text">{buildInfo}</span>

                    <span title="Start time" className="ms-2 bread-text">
                        {formattedDate}
                    </span>
                </Col>
                <Col
                    className={cx(
                        "text-start ps-2",
                        {
                            "col-md-5 col-lg-6":
                                revisionType !== ReleaseType.Development &&
                                revisionType !== ReleaseType.Review
                        },
                        {
                            "col-md-5 col-lg-6":
                                revisionType === ReleaseType.Development
                        },
                        { "col-9": !developerMode }
                    )}
                >
                    {developerMode && object.buildType === BuildType.Mutator ? (
                        <Badge bg="mutator" className="ms-2">
                            Mutator
                        </Badge>
                    ) : null}
                    {object.state !== BatchBuildState.Completed && (
                        <Badge
                            className="ms-2"
                            bg={getBadgeVariation(object.state)}
                        >
                            {BatchBuildState[object.state]}
                        </Badge>
                    )}
                    {isLatestBuild ? (
                        <Badge
                            bg={getBadgeVariation("latest")}
                            className="ms-2"
                        >
                            Latest
                        </Badge>
                    ) : null}
                    {object.state < BatchBuildState.Completed ||
                    object.state === BatchBuildState.Failed ? (
                        <StepProgressBar build={object} />
                    ) : null}
                    <div
                        style={{ paddingTop: "3px" }}
                        className="d-none d-lg-inline-block float-end"
                    >
                        <UserQueryBadges
                            reference="build"
                            referenceId={Number(object.id)}
                        />
                        <DailyBadges
                            reference="build"
                            referenceId={Number(object.id)}
                        />
                    </div>
                </Col>
                {developerMode && object.buildType !== BuildType.Mutator && (
                    <Col className="col-md-4 col-lg-3 d-flex px-0 justify-content-end align-items-center">
                        {analyticsEnabled &&
                        analyticsFunnelSelectionMode &&
                        object.state >= BatchBuildState.Completed &&
                        object.state !== BatchBuildState.Failed &&
                        object.state !== BatchBuildState.Stopped &&
                        object.analyticsEnabled ? (
                            <Button
                                className="btn-sm me-1"
                                onClick={(event: SyntheticEvent) => {
                                    event.stopPropagation();
                                    toggleFunnelSelection();
                                }}
                                variant={isInFunnel ? "analytics" : "secondary"}
                            >
                                <FontAwesomeIcon icon={faFilter} size="1x" />
                            </Button>
                        ) : null}
                        {revisionType === ReleaseType.Development &&
                        object.state === BatchBuildState.Completed ? (
                            <Button
                                onClick={(event: SyntheticEvent) => {
                                    event.preventDefault();
                                    event.stopPropagation();
                                    updateBuildState(
                                        Number(object.id),
                                        BatchBuildState.Test
                                    );
                                }}
                                title="Tag batch as test"
                                variant="test"
                                className="btn-sm me-1"
                            >
                                Test{" "}
                                <FontAwesomeIcon
                                    className="mt-1 ms-1"
                                    icon={faCheck}
                                    size="xs"
                                />
                            </Button>
                        ) : null}
                        {revisionType === ReleaseType.Development &&
                        object.state === BatchBuildState.Test ? (
                            <Button
                                onClick={(event: SyntheticEvent) => {
                                    event.preventDefault();
                                    event.stopPropagation();
                                    updateBuildState(
                                        Number(object.id),
                                        BatchBuildState.Completed
                                    );
                                }}
                                variant="test"
                                title="Untag batch as test"
                                className="btn-sm me-1"
                            >
                                Test{" "}
                                <FontAwesomeIcon
                                    className="mt-1 ms-1"
                                    icon={faXmark}
                                    size="xs"
                                />
                            </Button>
                        ) : null}
                        {(revisionType === ReleaseType.Development ||
                            revisionType === ReleaseType.Review) &&
                        object.state === BatchBuildState.Completed ? (
                            <Button
                                onClick={(event: SyntheticEvent) => {
                                    event.preventDefault();
                                    event.stopPropagation();
                                    updateBuildState(
                                        Number(object.id),
                                        BatchBuildState.Review
                                    );
                                }}
                                variant="review"
                                title="Tag batch as review"
                                className="btn-sm"
                            >
                                Review{" "}
                                <FontAwesomeIcon
                                    className="mt-1 ms-1"
                                    icon={faCheck}
                                    size="xs"
                                />
                            </Button>
                        ) : null}
                        {(revisionType === ReleaseType.Development ||
                            revisionType === ReleaseType.Review) &&
                        object.state === BatchBuildState.Review ? (
                            <Button
                                onClick={(event: SyntheticEvent) => {
                                    event.preventDefault();
                                    event.stopPropagation();
                                    updateBuildState(
                                        Number(object.id),
                                        BatchBuildState.Completed
                                    );
                                }}
                                variant="review"
                                className="btn-sm"
                                title="Untag batch as review"
                            >
                                Review{" "}
                                <FontAwesomeIcon
                                    className="mt-1 ms-1"
                                    icon={faXmark}
                                    size="xs"
                                />
                            </Button>
                        ) : null}
                        {object.state <= BatchBuildState.JobCleaned &&
                        object.jobIds &&
                        object.jobIds.length > 0 ? (
                            <Button
                                onClick={(event: SyntheticEvent) => {
                                    event.preventDefault();
                                    event.stopPropagation();
                                    startCancelJob();
                                }}
                                variant="danger"
                                className="btn-sm"
                            >
                                Cancel
                            </Button>
                        ) : null}
                    </Col>
                )}
                {developerMode && object.buildType === BuildType.Mutator && (
                    <Col className="col-md-4 col-lg-3"></Col>
                )}
            </Row>
        </ListGroup.Item>
    );
};
