import React, { FC, useEffect } from "react";
import { Button, Card, Col, Modal, Row } from "react-bootstrap";
import {
    BuildNotification,
    NotificationType,
    StateData,
    UserNotification,
    UserNotificationQueryData,
    UserNotificationRemoveData,
    UserNotificationUpdateVariables
} from "../../models/types";
import { useApolloClient, useMutation } from "@apollo/client";
import {
    REMOVE_NOTIFICATION,
    UPDATE_NOTIFICATION
} from "../../graphql/mutations";
import { GET_LOCAL_STATE, GET_NOTIFICATIONS } from "../../graphql/queries";
import { useLocalState } from "../../graphql/hooks";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCircleExclamation,
    faXmark
} from "@fortawesome/free-solid-svg-icons";
import { getFormattedTime } from "../../common/Helpers";
import cx from "classnames";

interface Props {
    show: boolean;
    notifications: UserNotification[] | undefined;
    buildNotifications: BuildNotification[];
    onClose: () => void;
}

export const NotificationsModal: FC<Props> = ({
    show,
    notifications,
    buildNotifications,
    onClose
}) => {
    const client = useApolloClient();
    const state = useLocalState();
    const [updateNotificationMutation] = useMutation<
        UserNotification,
        UserNotificationUpdateVariables
    >(UPDATE_NOTIFICATION);

    const [removeNotificationMutation] = useMutation<
        UserNotificationRemoveData,
        UserNotificationUpdateVariables
    >(REMOVE_NOTIFICATION);

    useEffect(() => {
        if (
            state.buildNotifications.some(
                (item: BuildNotification) => item.isNew
            )
        ) {
            const updatedBuildNotifications = state.buildNotifications.map(
                (notification: BuildNotification) => {
                    if (notification.isNew) {
                        return { ...notification, isNew: false };
                    }
                    return notification;
                }
            );
            client.writeQuery<StateData, StateData>({
                query: GET_LOCAL_STATE,
                data: {
                    state: {
                        ...state,
                        buildNotifications: updatedBuildNotifications
                    }
                }
            });
        }
    });

    const clearBuildNotification = (id: number) => {
        const updatedNotifications = buildNotifications.filter(
            item => item.id !== id
        );
        client.writeQuery<StateData, StateData>({
            query: GET_LOCAL_STATE,
            data: {
                state: {
                    ...state,
                    buildNotifications: updatedNotifications
                }
            }
        });
    };

    const clearUserNotification = async (id: number) => {
        const result = await removeNotificationMutation({
            variables: {
                notificationId: id
            }
        });
        if (result?.data?.removeNotification) {
            const currentNotificationsData =
                client.cache.readQuery<UserNotificationQueryData>({
                    query: GET_NOTIFICATIONS
                });
            const currentNotifications =
                currentNotificationsData?.getNotifications;
            if (currentNotifications) {
                const updatedNotifications =
                    notifications?.filter(item => item.id !== id) || [];
                client.cache.writeQuery<UserNotificationQueryData>({
                    query: GET_NOTIFICATIONS,
                    data: { getNotifications: updatedNotifications }
                });
            }
        }
    };

    const buildNotificationElements = buildNotifications?.map(
        (notification: BuildNotification, index: number) => (
            <Card key={index} className="mb-2 subtle-border">
                <Card.Body>
                    <Row className="mx-1 g-0">
                        <Col className="col-11">
                            <h6
                                className={cx(
                                    {
                                        "text-danger":
                                            notification.type ===
                                            NotificationType.Danger
                                    },
                                    {
                                        "text-success":
                                            notification.type ===
                                            NotificationType.Success
                                    },
                                    {
                                        "text-info":
                                            notification.type ===
                                            NotificationType.Info
                                    }
                                )}
                            >
                                {notification.title}
                            </h6>
                            {notification.isLink ? (
                                <Link
                                    onClick={() => onClose()}
                                    to={notification.path}
                                >
                                    <span className="medium-font">
                                        {notification.message}
                                    </span>
                                </Link>
                            ) : (
                                <span className="medium-font">
                                    {notification.message}
                                </span>
                            )}
                        </Col>
                        <Col
                            onClick={() =>
                                clearBuildNotification(notification.id)
                            }
                            className="col-1 d-flex justify-content-end mouseHover"
                        >
                            <FontAwesomeIcon
                                icon={faXmark}
                                title="Clear notification"
                            />
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        )
    );

    const notificationElements = notifications?.map(
        (notification: UserNotification, index: number) => (
            <Card key={index} className="mb-2 subtle-border mouseHover">
                <Card.Body>
                    <Row className="mx-1 g-0">
                        <Col className="col-11">
                            <h6>
                                {notification.title}
                                {notification.isNew ? (
                                    <FontAwesomeIcon
                                        icon={faCircleExclamation}
                                        className="ms-2 text-success"
                                        title="New notification!"
                                        onClick={() =>
                                            updateNotificationMutation({
                                                variables: {
                                                    notificationId:
                                                        notification.id,
                                                    isNew: false
                                                }
                                            })
                                        }
                                    />
                                ) : null}
                            </h6>

                            <div className="medium-font">
                                {notification.message}
                            </div>
                            <div
                                style={{ color: "gray" }}
                                className="analytics-font mt-1"
                            >
                                {getFormattedTime(notification.dateCreated)}
                            </div>
                        </Col>
                        <Col className="col-1 d-flex justify-content-end">
                            <FontAwesomeIcon
                                icon={faXmark}
                                title="Clear notification"
                                onClick={() =>
                                    clearUserNotification(notification.id)
                                }
                            />
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        )
    );

    return (
        <Modal
            show={show}
            onHide={onClose}
            backdrop="static"
            size="xl"
            className={state.useDarkMode ? "modal-dark" : ""}
            onClick={(e: React.MouseEvent<HTMLElement>) => e.stopPropagation()}
        >
            <Modal.Header closeButton>
                <h5>Notifications</h5>
            </Modal.Header>
            <Modal.Body className="m-0">
                {buildNotifications.length > 0 ? (
                    <Card className="m-1 no-border">
                        <h6 className="p-2 m-2">Build Notifications</h6>
                        <Card.Body>{buildNotificationElements}</Card.Body>
                    </Card>
                ) : null}
                {notifications && notifications.length > 0 ? (
                    <Card className="m-1 no-border">
                        <h6 className="p-2 m-2">User Notifications</h6>
                        <Card.Body>{notificationElements}</Card.Body>
                    </Card>
                ) : null}
                {buildNotifications.length === 0 &&
                    (!notifications || notifications.length === 0) && (
                        <p className="p-2 m-3 text-inverse-50 bread-text">
                            No messages.
                            <br />
                            <br />
                            Product and/or user related notifications, that are
                            generated during the current browser session will be
                            shown here.
                        </p>
                    )}
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-center">
                <Button
                    variant="secondary"
                    onClick={(e: React.MouseEvent<HTMLElement>) => {
                        e.stopPropagation();
                        onClose();
                    }}
                >
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
