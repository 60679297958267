import React, { FC, useEffect, useState } from "react";
import {
    Badge,
    Card,
    Collapse,
    FormControl,
    InputGroup
} from "react-bootstrap";
import { MutatorProperty } from "../../../models/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { useMutatorState } from "../../../graphql/hooks";
import styles from "./CustomTypeCard.module.scss";
import cx from "classnames";

interface Props {
    fieldProperty: MutatorProperty;
    typeProperty: MutatorProperty;
    getVariantByType: (type: string) => string;
    onChange: (name: string, value: number) => void;
}

const MutatorNumberFieldCardImplementation: FC<Props> = ({
    fieldProperty,
    typeProperty,
    getVariantByType,
    onChange
}) => {
    const mutatorState = useMutatorState();
    const [showMeta, updateShowMeta] = useState(mutatorState.showMeta);
    const [notification, updateNotification] = useState<string | undefined>(
        undefined
    );

    useEffect(() => {
        if (showMeta !== mutatorState.showMeta) {
            updateShowMeta(mutatorState.showMeta);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mutatorState.showMeta]);

    const addNotification = (notification: string) => {
        updateNotification(notification);
        setTimeout(() => {
            updateNotification(undefined);
        }, 1000);
    };

    return (
        <Card className="text-inverse inner-card">
            <Card.Header
                className={`d-flex align-items-center ${styles.cardHeader}`}
            >
                {fieldProperty.name}
                <Badge
                    className="mx-2"
                    bg={getVariantByType(fieldProperty.type)}
                >
                    {fieldProperty.type}
                </Badge>
                {typeProperty.meta ? (
                    <div
                        className="float-end ms-auto mouseHover"
                        onClick={() => updateShowMeta(!showMeta)}
                        title="Help and additional info"
                    >
                        <FontAwesomeIcon
                            icon={faCircleInfo}
                            className={cx({
                                "text-inverse-25": !showMeta,
                                "text-inverse-50": showMeta
                            })}
                        />
                    </div>
                ) : null}
            </Card.Header>
            <Card.Body className={`${styles.cardBody}`}>
                <InputGroup>
                    <InputGroup.Text>Value</InputGroup.Text>
                    <FormControl
                        type="number"
                        value={fieldProperty.value}
                        onChange={e => {
                            const newValue = Number(e.target.value);
                            if (
                                typeProperty.meta?.min &&
                                newValue < typeProperty.meta.min
                            ) {
                                addNotification(
                                    `Minimum allowed value ${typeProperty.meta.min}`
                                );
                                return;
                            }
                            if (
                                typeProperty.meta?.max &&
                                newValue > typeProperty.meta.max
                            ) {
                                addNotification(
                                    `Maximum allowed value ${typeProperty.meta.max}`
                                );
                                return;
                            }
                            onChange(typeProperty.name, newValue);
                        }}
                    />
                </InputGroup>
            </Card.Body>
            {typeProperty.meta ? (
                <Collapse in={showMeta}>
                    <div>
                        <Card.Footer className={styles.footerInfo}>
                            {typeProperty.meta.description ? (
                                <div className="bread-text text-inverse-50">
                                    {typeProperty.meta.description}
                                </div>
                            ) : null}
                            {typeProperty.meta.min ? (
                                <div className="bread-text text-info">
                                    <span>Min: {typeProperty.meta.min}</span>
                                </div>
                            ) : null}
                            {typeProperty.meta.max ? (
                                <div className="bread-text text-info">
                                    <span>Max: {typeProperty.meta.max}</span>
                                </div>
                            ) : null}
                        </Card.Footer>
                    </div>
                </Collapse>
            ) : null}
            {notification ? (
                <Card.Footer className={styles.footerInfo}>
                    <div className="bread-text text-danger">{notification}</div>
                </Card.Footer>
            ) : null}
        </Card>
    );
};

export const MutatorNumberFieldCard = React.memo(
    MutatorNumberFieldCardImplementation
);
