import React, { FC } from "react";
import { Row } from "react-bootstrap";
import { VariationSettings } from "../../../models/types";
import { VariationViewItemProps } from "../../../models/common";
import { VariationSettingsItem } from "../../variationItems/VariationSettingsItem";
import "../views.scss";

export const Settings: FC<VariationViewItemProps> = ({
    revision,
    developerMode
}) => {
    if (!revision || !revision.buildData) {
        return null;
    }

    const variationSettings: VariationSettings = {
        nopixi: revision.buildData.nopixi,
        debug: revision.buildData.debug,
        generateReport: revision.buildData.generateReport,
        generateStats: revision.buildData.generateStats,
        namingScheme: revision.buildData.nameTemplate,
        pathScheme: revision.buildData.pathTemplate,
        analytics: revision.buildData.analytics,
        forceUppercasePath: revision.buildData.forceUppercasePath,
        useDurationNames: revision.buildData.useDurationNames,
        useLegacyLocalizations: revision.buildData.useLegacyLocalizations,
        useLegacyNetworks: revision.buildData.useLegacyNetworks,
        gzip: revision.buildData.gzip,
        useAditor: revision.buildData.useAditor,
        useMutator: revision.buildData.useMutator
    };

    return (
        <Row>
            <VariationSettingsItem
                key={revision.buildData.version}
                defaultValue={variationSettings}
                revision={revision}
                developerMode={developerMode}
            />
        </Row>
    );
};
