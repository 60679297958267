import React, { FC, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCheckDouble,
    faChevronDown,
    faChevronUp,
    faCircleInfo,
    faListCheck,
    faPlus
} from "@fortawesome/free-solid-svg-icons";
import {
    VariationCollapseState,
    VariationViewType
} from "../../../models/common";
import { Button, Card, Collapse, Container } from "react-bootstrap";
import {
    PartialRevisionData,
    PartialRevisionVariables,
    Revision
} from "../../../models/types";
import { Networks } from "./Networks";
import {
    getVariationCollapseState,
    saveVariationCollapseState,
    updateRevisionPartially
} from "../../../common/Helpers";
import { useApolloClient, useMutation } from "@apollo/client";
import { PARTIAL_UPDATE_REVISION } from "../../../graphql/mutations";
import cx from "classnames";

interface Props {
    revision: Revision;
    developerMode: boolean;
    openNewItemModal: (type: VariationViewType) => void;
}

const NetworksManagerImplementation: FC<Props> = ({
    revision,
    developerMode,
    openNewItemModal
}) => {
    const client = useApolloClient();
    const [showHelp, setShowHelp] = useState(false);
    const [showNetworks, setShowNetworks] = useState(
        getVariationCollapseState(VariationCollapseState.Networks)
    );
    const [partialUpdateRevision] = useMutation<
        PartialRevisionData,
        PartialRevisionVariables
    >(PARTIAL_UPDATE_REVISION);

    const selectAllNetworks = async (all: boolean) => {
        if (revision && revision.buildData) {
            const newNetworks = [...revision.buildData.networks];
            for (let i = 0; i < newNetworks.length; i++) {
                const sizeTargets = all ? [2, 3, 5] : [];
                newNetworks[i] = { ...newNetworks[i], sizeTargets };
            }
            const newBuildData = {
                networks: newNetworks
            };
            await updateRevisionPartially(
                client,
                revision,
                partialUpdateRevision,
                newBuildData
            );
        }
    };

    const updateShowNetworks = (newState: boolean) => {
        saveVariationCollapseState(VariationCollapseState.Networks, newState);
        setShowNetworks(newState);
    };

    const selectReviewNetworks = async () => {
        if (revision && revision.buildData) {
            const newNetworks = [...revision.buildData.networks];
            for (let i = 0; i < newNetworks.length; i++) {
                const sizeTargets =
                    newNetworks[i].network === "unity" ? [2, 3, 5] : [];
                newNetworks[i] = { ...newNetworks[i], sizeTargets };
            }
            const newBuildData = {
                networks: newNetworks
            };
            await updateRevisionPartially(
                client,
                revision,
                partialUpdateRevision,
                newBuildData
            );
        }
    };
    const toggleShowHelp = () => setShowHelp(!showHelp);
    const hasNetworks =
        revision.buildData &&
        Array.isArray(revision.buildData.networks) &&
        revision.buildData.networks.length > 0;

    return (
        <>
            <Container
                className={cx("mb-4 p-0", {
                    editPanelClientView: !developerMode
                })}
            >
                <Card>
                    <Card.Header className="d-flex align-items-center">
                        <h5 className="text-inverse-50 mb-1 me-2">Networks</h5>
                        {hasNetworks && (
                            <div
                                onClick={() => {
                                    updateShowNetworks(!showNetworks);
                                }}
                                data-toggle="tooltip"
                                title="Toggle networks visibility"
                                className="d-flex ms-1 align-items-center mouseHover text-inverse-50"
                            >
                                <FontAwesomeIcon
                                    icon={
                                        showNetworks
                                            ? faChevronUp
                                            : faChevronDown
                                    }
                                />
                            </div>
                        )}
                        {developerMode ? (
                            <>
                                <div className="float-end ms-auto">
                                    <Button
                                        onClick={() =>
                                            openNewItemModal(
                                                VariationViewType.Networks
                                            )
                                        }
                                        className="btn-sm me-2 mouseHover"
                                        data-toggle="tooltip"
                                        title="Add new network"
                                    >
                                        <FontAwesomeIcon
                                            icon={faPlus}
                                            size="lg"
                                        />
                                    </Button>
                                    <Button
                                        variant="primary"
                                        size="sm"
                                        onClick={() => selectReviewNetworks()}
                                        data-toggle="tooltip"
                                        title="Select review set of networks"
                                        disabled={!hasNetworks}
                                    >
                                        <FontAwesomeIcon icon={faListCheck} />
                                    </Button>
                                    <Button
                                        variant="success"
                                        size="sm"
                                        className="ms-2"
                                        onClick={() => selectAllNetworks(true)}
                                        data-toggle="tooltip"
                                        title="Select all networks"
                                        disabled={!hasNetworks}
                                    >
                                        <FontAwesomeIcon icon={faCheckDouble} />
                                    </Button>
                                    <Button
                                        variant="danger"
                                        size="sm"
                                        className="ms-2"
                                        onClick={() => selectAllNetworks(false)}
                                        data-toggle="tooltip"
                                        title="Remove all network selections"
                                        disabled={!hasNetworks}
                                    >
                                        <FontAwesomeIcon icon={faCheckDouble} />
                                    </Button>
                                </div>
                            </>
                        ) : (
                            <div className="float-end ms-auto">
                                <FontAwesomeIcon
                                    icon={faCircleInfo}
                                    onClick={toggleShowHelp}
                                    className={`mouseHover ${
                                        showHelp
                                            ? "text-inverse-50"
                                            : "text-inverse-25"
                                    }`}
                                    title={showHelp ? "Hide Help" : "Show Help"}
                                />
                            </div>
                        )}
                    </Card.Header>
                    {hasNetworks && (
                        <Collapse in={showNetworks}>
                            <div>
                                <Card.Body className="mb-2">
                                    <Networks
                                        revision={revision}
                                        developerMode={developerMode}
                                    />
                                </Card.Body>
                            </div>
                        </Collapse>
                    )}
                    <Collapse in={showHelp}>
                        <div>
                            <Card.Footer className="bread-text text-inverse-50">
                                <p>
                                    <strong>Networks</strong> selection will
                                    create one build, which will be compatible
                                    for the selected ad network, per each
                                    selected base version and variation .
                                </p>
                                <p>
                                    <strong>Please note!</strong> Some ad
                                    networks accept limited size targets, so
                                    amount of generated builds is dependent on
                                    individual networks; network build from a
                                    base version with non-compatible size will
                                    not be generated (there should be a warning
                                    when this happens).
                                </p>
                            </Card.Footer>
                        </div>
                    </Collapse>
                </Card>
            </Container>
        </>
    );
};

export const NetworksManager = React.memo(NetworksManagerImplementation);
