import React, { FC, useEffect, useState } from "react";
import {
    Badge,
    Button,
    Card,
    Col,
    FormControl,
    InputGroup
} from "react-bootstrap";
import { LocalizationUploadModal } from "../modals/LocalizationUploadModal";
import {
    Localization,
    LocalizationTagValidationData,
    LocalizationTagValidationVariables,
    Revision,
    Types
} from "../../models/types";
import { OverlayBadge } from "../simple/OverlayBadge";
import { useQuery } from "@apollo/client";
import { GET_IS_VALID_LOCALIZATION_TAG } from "../../graphql/queries";
import cx from "classnames";

interface Props {
    revision: Revision;
    defaultValue: Localization;
    developerMode: boolean;
    version: number;
    updateRevision: (newBuildData?: Types, operation?: string) => void;
    remove: () => void;
    view: (localizationData: { localization: string; s3Key: string }) => void;
}

const EditableLocalizationImplementation: FC<Props> = ({
    revision,
    defaultValue,
    version,
    developerMode,
    updateRevision,
    remove,
    view
}) => {
    const [showFileUpload, updateShowFileUpload] = useState(false);
    const [currentLocalization, updateCurrentLocalization] =
        useState(defaultValue);
    const [currentVersion, setCurrentVersion] = useState(version);
    const { data: { isValidLocalizationTag } = {} } = useQuery<
        LocalizationTagValidationData,
        LocalizationTagValidationVariables
    >(GET_IS_VALID_LOCALIZATION_TAG, {
        variables: {
            localizationTag: currentLocalization.name
        }
    });

    useEffect(() => {
        if (defaultValue && currentVersion < version) {
            setCurrentVersion(version);
            updateCurrentLocalization(defaultValue);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultValue, version]);

    const updateLocalization = (localizationData: Localization) => {
        if (revision) {
            const updatedLocalization = {
                ...currentLocalization,
                ...localizationData
            };
            updateCurrentLocalization(updatedLocalization);

            updateRevision({ localizations: [updatedLocalization] });
        }
    };

    // move this to backend?
    /*    const setAsDefault = () => {
        const newLocalizationData = {
            ...currentLocalization,
            default: true,
            include: true
        };
        const currentLocalizationData = revision.buildData.localizations;
        for (let i = 0; i < currentLocalizationData.length; i++) {
            if (currentLocalizationData[i].id === currentLocalization.id) {
                currentLocalizationData[i] = newLocalizationData;
            } else {
                currentLocalizationData[i].default = false;
            }
        }
        const newBuildData = {
            localizations: currentLocalizationData
        };
        updateCurrentLocalization(newLocalizationData);
        updateRevision(newBuildData);
    }; */
    const setAsDefault = () => {
        const newLocalizationData = [];
        const currentLocalizationData = [...revision.buildData.localizations];
        for (let i = 0; i < currentLocalizationData.length; i++) {
            if (currentLocalizationData[i].default === true) {
                currentLocalizationData[i] = {
                    ...currentLocalizationData[i],
                    default: false
                };
                newLocalizationData.push(currentLocalizationData[i]);
                continue;
            }
            if (currentLocalizationData[i].id === currentLocalization.id) {
                currentLocalizationData[i] = {
                    ...currentLocalizationData[i],
                    default: true,
                    include: true
                };
                newLocalizationData.push(currentLocalizationData[i]);
                updateCurrentLocalization(currentLocalizationData[i]);
                continue;
            }
            newLocalizationData.push(currentLocalizationData[i]);
        }
        const newBuildData = {
            localizations: newLocalizationData
        };
        updateRevision(newBuildData);
    };

    return (
        <>
            <Card
                className={cx("mb-2 inner-card fullSize", {
                    "border-danger": !isValidLocalizationTag
                })}
            >
                <Card.Header className="text-inverse p-2 d-flex align-items-center">
                    <Col className="d-flex align-items-center ps-0 col-1">
                        <InputGroup className="custom-input-background p-0">
                            <InputGroup.Checkbox
                                className="mouseHover"
                                title="Include in next Build set"
                                aria-label="Include in next Build set"
                                checked={currentLocalization.include}
                                disabled={currentLocalization.default}
                                onChange={(event: any) => {
                                    const newLocalizationData = {
                                        ...currentLocalization,
                                        include: event.target.checked
                                    };
                                    updateLocalization(newLocalizationData);
                                }}
                                onClick={() => {
                                    const newLocalizationData = {
                                        ...currentLocalization,
                                        include: !currentLocalization.include
                                    };
                                    updateCurrentLocalization(
                                        newLocalizationData
                                    );
                                    updateLocalization(newLocalizationData);
                                }}
                            />
                        </InputGroup>
                        {!isValidLocalizationTag && (
                            <div className="pe-3">
                                <Badge
                                    bg="danger"
                                    title="Localization tag is invalid!"
                                >
                                    !
                                </Badge>
                            </div>
                        )}
                    </Col>
                    {developerMode ? (
                        <Col className="d-flex col-sm-3 col-md-4 p-0 justify-content-start align-items-center">
                            <InputGroup>
                                <InputGroup.Text className="my-1">
                                    Name
                                </InputGroup.Text>
                                <FormControl
                                    className="my-1"
                                    title="Language abbreviation for localization"
                                    value={currentLocalization.name}
                                    onClick={(event: any) => {
                                        event.stopPropagation();
                                    }}
                                    onChange={(event: any) => {
                                        const newLocalizationData = {
                                            ...currentLocalization,
                                            name: event.target.value
                                        };
                                        updateCurrentLocalization(
                                            newLocalizationData
                                        );
                                    }}
                                    onBlur={(event: any) => {
                                        const newLocalizationData = {
                                            ...currentLocalization,
                                            name: event.target.value
                                        };
                                        updateLocalization(newLocalizationData);
                                    }}
                                />
                                <FormControl
                                    title="Full name of language or other description"
                                    placeholder="Description"
                                    className="ms-1 my-1"
                                    value={currentLocalization.description}
                                    onChange={(
                                        event: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                        const newLocalizationData = {
                                            ...currentLocalization,
                                            description: event.target.value
                                        };
                                        updateCurrentLocalization(
                                            newLocalizationData
                                        );
                                    }}
                                    onBlur={(
                                        event: React.FocusEvent<HTMLInputElement>
                                    ) => {
                                        const newLocalizationData = {
                                            ...currentLocalization,
                                            description: event.target.value
                                        };
                                        updateLocalization(newLocalizationData);
                                    }}
                                />
                            </InputGroup>
                        </Col>
                    ) : (
                        <Col className="d-flex col-sm-3 col-md-4 justify-content-start align-items-center">
                            {currentLocalization.name}
                            {currentLocalization.description && (
                                <span className="ms-3 text-inverse-50">
                                    {currentLocalization.description}
                                </span>
                            )}
                        </Col>
                    )}
                    <Col className="col-1 px-2">
                        {currentLocalization.default ? (
                            <Badge
                                bg="test"
                                title="Set as the fallback language for this revision"
                                className="p-1"
                            >
                                Default
                            </Badge>
                        ) : null}
                    </Col>
                    <Col className="d-flex col-sm-7 col-md-6 justify-content-end">
                        {!currentLocalization.default ? (
                            <Button
                                size="sm"
                                variant="test"
                                className="me-2 text-light"
                                onClick={() => setAsDefault()}
                            >
                                Set as Default
                            </Button>
                        ) : null}
                        <Button
                            size="sm"
                            variant="success"
                            className="me-2"
                            onClick={() => updateShowFileUpload(true)}
                        >
                            Upload
                        </Button>
                        <Button
                            size="sm"
                            variant="info"
                            onClick={() =>
                                view({
                                    localization: currentLocalization.name,
                                    s3Key: currentLocalization.localization
                                })
                            }
                        >
                            View
                        </Button>
                        <Button
                            size="sm"
                            variant="danger"
                            className="ms-2"
                            onClick={remove}
                        >
                            Remove
                        </Button>
                    </Col>
                </Card.Header>
            </Card>
            {showFileUpload ? (
                <LocalizationUploadModal
                    title={`Upload new ${currentLocalization.name} localization`}
                    updateRevision={updateRevision}
                    localization={currentLocalization.localization}
                    projectLocalizations={undefined}
                    onClose={() => updateShowFileUpload(false)}
                    revision={revision}
                    show={showFileUpload}
                />
            ) : null}
        </>
    );
};

export const EditableLocalization = React.memo(
    EditableLocalizationImplementation
);
