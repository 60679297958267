import React, { FC } from "react";
import { Dropdown } from "react-bootstrap";
import { AssetBundle, MutatorProperty } from "../../../models/types";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import styles from "./MutatorDropdown.module.scss";

interface Props {
    property: MutatorProperty | any;
    assetbundles: AssetBundle[];
    getVariantByType: (type: string) => string;
    onChange: (name: string, value: string) => void;
}

const AssetbundleDropdownImplementation: FC<Props> = ({
    property,
    assetbundles,
    getVariantByType,
    onChange
}) => {
    return (
        <>
            <Dropdown id={property.name}>
                <Dropdown.Toggle
                    className={styles.toggleButton}
                    variant={getVariantByType(property.type)}
                    id="dropdown-basic"
                >
                    {property.value.split("/").pop()}
                </Dropdown.Toggle>
                <DropdownMenu>
                    {assetbundles.map((element: any, index: number) => (
                        <Dropdown.Item
                            key={index}
                            onClick={(e: any) => {
                                e.preventDefault();
                                e.stopPropagation();
                                onChange(property.name, element.path);
                            }}
                        >
                            {element.name}
                        </Dropdown.Item>
                    ))}
                </DropdownMenu>
            </Dropdown>
        </>
    );
};

export const AssetbundleDropdown = React.memo(
    AssetbundleDropdownImplementation
);
