import { useMutation, useQuery } from "@apollo/client";
import React, { FC, useState } from "react";
import { Badge, Button, Card, Collapse } from "react-bootstrap";
import {
    Project,
    ProjectConfigData,
    ProjectConfigVariables,
    SoftLimitsData,
    UpdateProjectConfigData
} from "../../../models/types";
import { GET_PROJECT_CONFIG } from "../../../graphql/queries";
import { EditableJsonView } from "../../editable/EditableJsonView";
import { UPDATE_PROJECT_CONFIG } from "../../../graphql/mutations";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

interface Props {
    project: Project;
}

export const ProjectConfig: FC<Props> = ({ project }) => {
    const [show, toggleShow] = useState(false);
    const [updating, updateUpdating] = useState(false);
    const [hasUnsaved, updateHasUnsaved] = useState(false);
    const { data: { projectConfig } = {} } = useQuery<ProjectConfigData>(
        GET_PROJECT_CONFIG,
        {
            variables: {
                projectId: project.id
            }
        }
    );
    const [projectConfigMutation] = useMutation<
        UpdateProjectConfigData,
        ProjectConfigVariables
    >(UPDATE_PROJECT_CONFIG);

    const createProjectConfig = () => {
        updateProjectConfig({ global: [] });
    };

    const updateProjectConfig = async (newLimits: SoftLimitsData) => {
        updateUpdating(true);
        try {
            await projectConfigMutation({
                variables: {
                    input: {
                        projectId: project.id,
                        softLimits: newLimits
                    }
                },
                refetchQueries: [
                    {
                        query: GET_PROJECT_CONFIG,
                        variables: { projectId: project.id }
                    }
                ]
            });
        } catch (error) {
            console.log("[DEBUG] error ", error);
        }
        updateUpdating(false);
    };

    return (
        <Card className="mt-2">
            <Card.Header>
                Project Config
                <FontAwesomeIcon
                    icon={show ? faChevronUp : faChevronDown}
                    className="mouseHover mx-2"
                    onClick={() => {
                        toggleShow(!show);
                    }}
                    title={show ? "Hide details" : "Show details"}
                />
                {hasUnsaved ? (
                    <div className="d-flex float-end ms-auto">
                        <Badge bg="warning">Changes Not Saved</Badge>
                    </div>
                ) : null}
            </Card.Header>
            <Collapse in={show}>
                <div>
                    <Card.Body className="d-flex justify-content-center">
                        {projectConfig === undefined ? (
                            <Button onClick={createProjectConfig}>
                                Create Config
                            </Button>
                        ) : (
                            <EditableJsonView
                                jsonData={JSON.stringify(
                                    projectConfig.softLimits,
                                    null,
                                    2
                                )}
                                updateJsonData={updateProjectConfig}
                                updating={updating}
                                updateHasUnsavedData={updateHasUnsaved}
                            />
                        )}
                    </Card.Body>
                </div>
            </Collapse>
        </Card>
    );
};
