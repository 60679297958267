import React, { FC } from "react";
import { Dropdown } from "react-bootstrap";
import { MutatorEnums, MutatorProperty } from "../../../models/types";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import styles from "./MutatorDropdown.module.scss";

interface Props {
    isOptional: boolean;
    property: MutatorProperty | any;
    enums: MutatorEnums;
    getVariantByType: (type: string) => string;
    onChange: (value: string) => void;
}

const EnumDropdownImplementation: FC<Props> = ({
    isOptional,
    property,
    enums,
    getVariantByType,
    onChange
}) => {
    const options = enums[property.type].options.map(
        (element: any, index: number) => (
            <Dropdown.Item
                key={index}
                onClick={(e: any) => {
                    e.preventDefault();
                    e.stopPropagation();
                    onChange(element.name);
                }}
            >
                {element.name}
            </Dropdown.Item>
        )
    );
    if (isOptional) {
        options.unshift(
            <Dropdown.Item
                key={-1}
                onClick={(e: any) => {
                    e.preventDefault();
                    e.stopPropagation();
                    onChange("None");
                }}
            >
                None
            </Dropdown.Item>
        );
    }
    return (
        <>
            <div>
                <Dropdown id={property.name}>
                    <Dropdown.Toggle
                        variant={getVariantByType(property.type)}
                        id="dropdown-basic"
                        className={styles.toggleButton}
                    >
                        {property.value}
                    </Dropdown.Toggle>
                    <DropdownMenu>{options}</DropdownMenu>
                </Dropdown>
            </div>
        </>
    );
};

export const EnumDropdown = React.memo(EnumDropdownImplementation);
