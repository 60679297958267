import React, { FC } from "react";
import { Button, Card, Col, Container, InputGroup } from "react-bootstrap";
import {
    Network,
    PartialRevisionData,
    PartialRevisionVariables,
    PlatformConfigData
} from "../../models/types";
import { VariationItemProps } from "../../models/common";
import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import { updateRevisionPartially } from "../../common/Helpers";
import { PARTIAL_UPDATE_REVISION } from "../../graphql/mutations";
import { GET_PLATFORM_CONFIG } from "../../graphql/queries";
import "./variationItems.scss";
import cx from "classnames";

interface NetworkProps extends VariationItemProps {
    network: Network;
    useAnalytics: boolean;
}

export const NetworkItem: FC<NetworkProps> = ({
    network,
    revision,
    useAnalytics
}) => {
    const client = useApolloClient();
    const { data: { getPlatformConfig } = {} } =
        useQuery<PlatformConfigData>(GET_PLATFORM_CONFIG);
    const [partialUpdateRevision] = useMutation<
        PartialRevisionData,
        PartialRevisionVariables
    >(PARTIAL_UPDATE_REVISION);

    const updateNetworkIncludes = async (include: boolean, size = -1) => {
        const sizeTargets = [...network.sizeTargets];
        if (include) {
            sizeTargets.push(size);
        } else {
            const index = sizeTargets.indexOf(size);
            if (index > -1) {
                sizeTargets.splice(index, 1);
            }
        }
        const networkData = { ...network, sizeTargets };
        if (revision) {
            await updateRevisionPartially(
                client,
                revision,
                partialUpdateRevision,
                { networks: [networkData] }
            );
        }
    };

    const updateNetworkAnalytics = async (analytics: boolean) => {
        if (revision) {
            const networkData = { ...network, analytics };
            await updateRevisionPartially(
                client,
                revision,
                partialUpdateRevision,
                { networks: [networkData] }
            );
        }
    };

    const remove = async () => {
        if (revision) {
            await updateRevisionPartially(
                client,
                revision,
                partialUpdateRevision,
                { networks: [network] },
                "delete"
            );
        }
    };

    const isAnalyticsNetwork = getPlatformConfig?.analyticsNetworks.includes(
        network.network
    );

    return (
        <Col xs="auto">
            <Card className="inner-card my-2 p-0">
                <Card.Header className="d-flex text-inverse justify-content-end align-items-center me-0 pe-1">
                    <Container
                        className={cx("m-0 p-0", { "col-6": useAnalytics })}
                    >
                        {network.network}
                    </Container>
                    {useAnalytics ? (
                        <Container className="m-0 p-0 col-6">
                            <InputGroup className="custom-input-background d-flex justify-content-end">
                                <InputGroup.Text
                                    className={cx(`pe-0 bread-text`, {
                                        "text-inverse-50": !isAnalyticsNetwork,
                                        "text-inverse": isAnalyticsNetwork
                                    })}
                                >
                                    Analytics
                                </InputGroup.Text>
                                <InputGroup.Checkbox
                                    title="Use analytics in next build set"
                                    aria-label="Use analytics in next Build set"
                                    checked={network.analytics}
                                    onChange={(event: any) => {
                                        updateNetworkAnalytics(
                                            event.target.checked
                                        );
                                    }}
                                    disabled={!isAnalyticsNetwork}
                                />
                            </InputGroup>
                        </Container>
                    ) : null}
                </Card.Header>
                <Card.Body className="m-0 p-2 d-flex flex-column">
                    <InputGroup className="m-0 p-0 mb-3">
                        <InputGroup.Text>2MB</InputGroup.Text>
                        <InputGroup.Checkbox
                            title="Include 2MB size in next the Build set"
                            aria-label="Include 2MB size in next the build set"
                            checked={network.sizeTargets.includes(2)}
                            onChange={(event: any) => {
                                updateNetworkIncludes(event.target.checked, 2);
                            }}
                        />
                        <InputGroup.Text className="ms-1">3MB</InputGroup.Text>
                        <InputGroup.Checkbox
                            title="Include 3MB size in next the Build set"
                            aria-label="Include 3MB size in next the build set"
                            checked={network.sizeTargets.includes(3)}
                            onChange={(event: any) => {
                                updateNetworkIncludes(event.target.checked, 3);
                            }}
                        />
                        <InputGroup.Text className="ms-1">5MB</InputGroup.Text>
                        <InputGroup.Checkbox
                            title="Include 5MB size in next the Build set"
                            aria-label="Include 5MB size in next the build set"
                            checked={network.sizeTargets.includes(5)}
                            onChange={(event: any) => {
                                updateNetworkIncludes(event.target.checked, 5);
                            }}
                        />
                    </InputGroup>
                    <Button className="btn-danger" onClick={remove}>
                        Remove
                    </Button>
                </Card.Body>
            </Card>
        </Col>
    );
};
